.App-container {
    justify-content: flex-start;
}

.app-header.app-header-game{
    .logo {
        min-width: 433px;
    }
    @media screen and (max-width: 1520px) {
      .logo {
        order: unset;
      }
      .info-part {
        order: unset;
      }
      .main-part {
        order: unset;
        width: auto;
        margin-top: 0;
      }
    }
}

.game-timer {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    img {
        width: 23px;
        height: 23px;
        margin-right: 15px;
    }
}

.gamePage-container {
    max-width: 1160px;
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 78px;
        text-align: center;
    }
    .products-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .vs {
            padding: 0 64px;
        }
    }
    .product-item-container {
        position: relative;
        .product-item {
            width: 488px;
            min-height: 430px;
            background: #323232;
            border-radius: 12px;
            color: #ffffff;
            .content {
                padding: 24px 44px 36px 32px;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                h6 {
                    font-family: 'Rubik', sans-serif;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                }
            }
        }
        .answer-img {
            position: absolute;
            width: 98px;
            height: 98px;
            top: calc(-98px / 2);
            right: calc(-98px / 2);
        }
    }
}

@media only screen and (max-width: 1220px) {
    .gamePage-container {
        max-width: 100%;
        .game-timer {
            margin-bottom: 24px;
        }
        .title {
            font-size: 24px;
            margin: 0 0 48px;
            text-align: center;
        }
        .products-container {
            .vs {
                padding: 0;
                margin: 0 39px;
                width: 42px;
            }
        }
        .product-item-container {
            position: relative;
            width: 100%;

            .product-item {
                max-width: 400px;
                // width: 100%;
                min-height: 348px;
                border-radius: 9px;
                .content {
                    padding: 19px 36px 24px 26px;
                    font-size: 20px;
                    line-height: 26px;
                    h6 {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
            .answer-img {
                width: 80px;
                height: 80px;
                top: calc(-80px / 2);
                right: calc(-80px / 2);
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .gamePage-container {
        .game-timer {
            margin-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;
            padding-top: 26px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 11px;
            }
        }
        .title {
            font-size: 16px;
            line-height: 32px;
            margin: 0;
            padding-bottom: 28px;
        }
        
        .products-container {
            justify-content: center;
            flex-direction: column !important;
            position: relative;
            min-height: calc(100vh - var(--header) - 118px);
            width: 100%;
            .product-item-container {
                .product-item {
                    min-height: 244px;
                    border-radius: 6px;
                    .content {
                        padding: 12px 16px 19px 16px;
                        h5 {
                            font-weight: 700 !important;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        h6 {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
                .answer-img {
                    width: 64px;
                    height: 64px;
                    top: calc(-64px / 2);
                    right: calc(-64px / 2);
                }
            }
            .vs {
                padding: 24px 0;
                margin: 0;
                width: 25px;
            }
            
            
        }
    }        
}

@media only screen and (max-width: 480px) {
    .gamePage-container {
        width: 100%;
        .products-container {
            .product-item-container {
                width: 100% !important;
                max-height: 244px;
                .product-item {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

