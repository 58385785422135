.gameLoading-container {
    min-height: inherit;
}

@media only screen and (max-width: 1024px) {
    .gameLoading-container {
        .loading-logo {
            display: block !important;
        }
    }
}

@media only screen and (max-width: 480px) {
    .gameLoading-container {
        .loading-logo {
            display: block;
            width: 219px;
            height: 219px;
        }
    }
}