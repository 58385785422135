@mixin config-classes($prefix, $afterPrefix...) {
    @each $item in $afterPrefix {
        .#{$prefix}-#{nth($item, 1)}  {
            #{$prefix}: nth($item, 1);
        }
    }
}

@mixin xl {
    @media (max-width: ($xl + 'px')) {
        @content;
    }
}

@mixin lg {
    @media (max-width: ($lg + 'px')) {
        @content;
    }
}

@mixin md {
    @media (max-width: ($md + 'px')) {
        @content;
    }
}

@mixin sm {
    @media (max-width: ($sm + 'px')) {
        @content;
    }
}

@mixin xs {
    @media (max-width: ($xs + 'px')) {
        @content;
    }
}