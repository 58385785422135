@import-normalize;
@import './styles/mixins';
@import './styles/variables';

body {
  margin: 0;
  font-family: 'Play', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1E1E1E;
  color: #FFFFFF;
  // overflow-y: hidden;//?
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column;
}

@include config-classes('align-items', center, flex-end);
@include config-classes('justify-content', center, end);

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.mb-2 {
  margin-bottom: 20px;
}

.error {
  color: $error !important;
}

.app-btn {
  position: relative;
  .btn-highlights {
    position: absolute;
    top: 5px;
    left: 13px;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.word-break {
  word-break: break-all;
}


.App {
  padding: 0 80px;
  min-height: 100vh;
  background-image: url(../public/img/bg.png);
  background-repeat: repeat;
  max-width: $xl;
  @media only screen and (max-width: 1024px) {
    padding: 0 52px;
  }
  @media only screen and (max-width: 574px) {
    padding: 0 20px;
  }
}

.App-container {
  min-height: calc(100vh - 107px); //107px header
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.app-header {
  padding-top: 32px;
  .logo {
    min-width: 248px;
    img {
      height: 80px;
      // position: relative;
      // top: 20px;
      // left: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 20px;
    .logo {
      img {
        height: 59px;
        top: 0;
        left: 0;
      }
    }
  }
  @media only screen and (max-width: 574px) {
    padding-top: 20px;
  }
  @media only screen and (max-width: 574px) {
    .logo {
      img {
        height: 35px;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .logo {
      img {
        height: 30px;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .app-header .logo {
    min-width: unset !important;
  }
}


@media only screen and (max-width: 1024px) {
  .App {
    .input > input {
      font-size: 24px;
      line-height: 32px;
      padding: 16px 40px;
    }
  }
}

@media only screen and (max-width: 790px) {
  .App {
    .input > input {
      font-size: 18px;
      line-height: 32px;
      padding: 8px 32px;
    }
  }
}