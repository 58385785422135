@import '../../styles/_variables.scss';

.send-result-container {
    // margin-top: 56px;
    padding: 20px 0;
    max-width: 715px;
    min-height: inherit;
    text-align: center;
    justify-content: center;
  .send-result {
    .subtitle {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
    }
    .title {
      margin: 16px 0 48px;
      font-weight: 700;
      font-size: 40px;
      line-height: 45px;
      a {
        color: #5CEBDA;
      }
    }
  }
  .form-container {
    padding: 0 110px;
  }
  .form-title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    // width: 666px;
    padding: 0 24px;
  }
  .label-container {
    margin-top: 42px;
    margin-bottom: 42px;
    height: 42px;
  }
  .app-btn {
    .send-result-btn {
      margin: 42px 0 0;
    }
  }  
  .skip {
    button {
      text-transform: capitalize;
      padding: 27px 0;
      font-family: 'Play';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 1024px){
  .send-result-container {
      // margin-top: 61px;
      max-width: 610px;
    .send-result {
      .subtitle {
        font-size: 20px;
        line-height: 32px;
      }
      .title {
        margin: 4px 0 24px;
        font-size: 28px;
        line-height: 45px;
      }
    }
    .form-container {
      padding: 0;
    }
    .form-title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .label-container {
      margin-top: 29px;
      margin-bottom: 29px;
      height: 42px;
      .agree {
        font-size: 18px;
        line-height: 32px;
      }
    }
    .app-btn {
      .send-result-btn {
        margin: 24px 0 0;
      }
    }
    .skip {
      margin-top: 2px;
      button {
        font-size: 24px;
        line-height: 28px;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 790px) { 
  .send-result-container {
      margin-top: 0;
      min-height: inherit;
      max-width: 100%;
      justify-content: center;
    .send-result {
      .subtitle {
        font-size: 16px;
        line-height: 24px;
      }
      .title {
        margin: 12px 0 40px;
        font-size: 20;
        line-height: 32px;
      }
      .app-btn {
        .send-result-btn {
          padding: 10px 0 !important;
          font-size: 18px !important;
          line-height: 27px !important;
        }
      }        
    }
    .form-title {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
    .label-container {
      margin-top: 24px;
      margin-bottom: 24px;
      height: unset;
      .agree {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .app-btn {
      .send-result-btn {
        margin: 24px 0 16px;
      }
    }
    .skip {
      // margin-top: 29px;
      button {
        font-size: 18px;
        line-height: 21px;
        padding: 0;
      }
    }
  }
}

