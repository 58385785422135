$primary: #5CEBDA;
// $secondary: #404040;
$error: #EB5757;

$placeholder: #A8A8A8;

//breakpoints
$xs: 0;
$sm: 575; //375
$md: 1025; //1024
$lg: 1440; //1440
$xl: 1920;