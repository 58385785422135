@import '../../styles/_variables.scss';

.game-over-container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .time-over {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .text {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 49px;
    }
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 46px;
      margin-bottom: 16px;
    }
    .sub-title {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 43px;
    }
  }

  .recalculation {
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    .game-score {
      position: relative;
      .score-text {
        padding: 17px 0;
      }
      .coin-img {
        margin: 0 11px 0 18px;
        height: 52px;
      }
    }
    .plus-score {
      position: absolute;
      top: -50%;
      left: 80%;
      display: flex;
      align-items: center;
      margin-left: 80px;
      .plus {
        width: 30px;
        margin-right: 11px;
      }
      span {
        display: block;
        font-weight: 700;
        font-size: 42px;
        line-height: 47px;
        color: #4DC43B;
        transform: rotate(-16.41deg);
        padding-bottom: 43px;
      }
    }
  }
  .show-btn-container {
    padding-top: 63px;
    .show-btn {
      width: 425px;
    }
  }

  .game-over {
    text-align: center;
    height: 0;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      padding-bottom: 15px;
      > a {
        color: $primary;
      }
    }
    .subtitle {
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding-bottom: 81px;
    }
    .form-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      padding-bottom: 41px;
    }
    .agree {
      font-family: 'Rubik', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 42px;
    }
  }
}

// @media only screen and (max-width: 1220px) { 
//   .gamePage-timer-container {
//     position: fixed;
//     margin-top: 70px;
//   }
// }

@media only screen and (max-width: 1024px) { 
  .game-over-container {

    .recalculation {
      .text {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 30px;
      }
      .plus-score {
        left: 100%;
        margin-left: 10px;
      }
    }
  }  
}

@media only screen and (max-width: 790px) { 
  .gamePage-timer-container {
    margin-top: 26px;
  }
  .game-over-container .time-over {
    .text {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 32px;
    }
    .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .sub-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .game-over-container {
    .show-btn-container {
      padding-top: 24px;
      .show-btn {
        width: 610px;
        padding: 10px !important;
        font-size: 18px !important;
        line-height: 27px !important;
      }
    }
    .recalculation {
      .game-score {
        font-size: 24px;
        line-height: 28px;
        .coin-img {
          height: 45px;
        }
      }
      .plus-score {
        left: 100%;
        top: -100%;
        margin-left: 10px;
      }
    }
  }

  @media only screen and (max-width: 700px) { 
    .game-over-container {
      .recalculation {
        .plus-score {
          margin: 0;
          top: -50%;
          .plus {
            width: 15px;
            margin-right: 11px;
          }
          span {
            font-size: 27px;
            line-height: 27px;
            padding-bottom: 25px;
          }
        }
      }
      .show-btn-container {
        width: 100%;
        .show-btn {
          width: 100%;
        }
      }
      .timer-c {
        width: 100%;
      }
    }
  }
}
