$container-width: 856px;

.App-container {
    justify-content: flex-start;
}

.app-header.app-header-leaderboard {
    @media screen and (max-width: 1520px) {
      .logo {
        order: 1;
      }
      .info-part {
        order: 2;
      }
      .main-part {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 16px;
      }
    }
    @media only screen and (max-width: 1024px) {
        .main-part {
            margin-top: 8px;
        }
    }
    @media only screen and (max-width: 574px) {
        .main-part {
            margin-top: 20px;
        }
    }
}

.logo-leaderboard {
    position: fixed;
    bottom: 0;
    right: 0;
    opacity: 0.9;
    @media only screen and (max-width: 1024px) {
        display: none;
    }
}

.leaderboard-container {
    max-width: calc($container-width + 12*2px + 8px + 128px); //856 +12 * 2
    width: calc(100% + 128px);
    padding: 20px 0 24px;
    margin-right: 128px;
    z-index: 2;

    .scroll-container {
        padding-left: 128px;
        // max-height: 754px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
            width: 8px;
            height: 0px;
            padding-left: 13px;
        }
        &::-webkit-scrollbar-track{
            -webkit-border-radius: 12px;
            border-radius: 12px;
            border: 2px solid #282828;
        }
        &::-webkit-scrollbar-thumb {
            background: linear-gradient(0deg, #3D3D3D, #3D3D3D), #3D3D3D;
            -webkit-border-radius: 12px;
            border-radius: 12px;
        }
        @media only screen and (max-width: 1024px) {
            padding-left: 0;
            max-height: none;
        }
    }

    .line {
        height: 8px;
        width: 100%;
        background-image: url(../../../public/img/dashed-line.svg);
        background-repeat: no-repeat;
        margin-bottom: 12px;
    }
   
    .playerItem-container {
        margin-bottom: 16px;
        margin-left: 14px;
        margin-right: 12px;
        .item {
            height: 64px;
            background: #282828;
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
        }
        .place {
            width: 80px;
            img {
                width: 52px;
                height: 52px;
            }
        }
        .player-name {
            justify-content: flex-start;
            padding: 12px;
            margin: 0 12px;
            .avatar {
                margin-right: 12px;
            } 
        }
        .score {
            padding: 12px 40px 12px 24px;
            img {
                width: 39px;
                height: 41px;
                margin-right: 42px;
            }
            .score-item {
                min-width: 56px
            }
        }
        .you {
            position: relative;
            .item {
                border: 1px solid #31B7F8;
                background: #3C3C3C;
            }
            .arrow-container {
                position: absolute;
                top: calc(50% - 18px);
                left: -128px;
                span {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 42px;
                    margin-right: 19px;
                }
            }
        }
    }
    .playerItem-container:last-child {
        margin-bottom: 30px;
    }
}

.leaderboard-actions {
    margin-bottom: 24px;
    width: 100%;
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    .new-game-btn {
        width: 373px;
    }
    .replay-btn {
        padding-top: 14px !important; 
        padding-bottom: 14px !important;
        width: 308px;
        border: 4px solid rgba(255, 255, 255, 0.5);
        background: none !important;
        img {
            width: 35px;
            height: 35px;
        }
        margin: 0 24px;
    }
    .leaderboard-timer {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
    }
}
@media only screen and (max-width: 1080px) {
    .leaderboard-container {
        .playerItem-container {
            .you {
                .arrow-container {
                    left: -95px;
                    span {
                        margin-right: 10px;            
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .leaderboard-container {
        max-width: calc(100% + 39px);
        width: calc(100% + 39px);
        margin-right: 39px;
        padding: 12px 0 0;
        .scroll-container {
            padding-left: 39px;
        }
        .playerItem-container {
            margin-bottom: 12px;
            margin-left: 0;
            margin-right: 0;

            .item {
                height: 48px;
                border-radius: 8px;
                font-size: 16px;
                line-height: 19px;
                border-radius: 12px;
            }
            .place {
                width: 60px;
                img {
                    width: 39px;
                    height: 39px;
                }
            }
            .player-name {
                padding: 8px;
                .avatar {
                    width: 32px;
                    height: 32px;
                } 
            }
            .score {
                padding: 7px 32px 7px 22px;
                img {
                    width: 33px;
                    height: 35px;
                    margin-right: 25px;
                }
            }
            .you {
                .arrow-container {
                    position: absolute;
                    top: calc(50% - 13px);
                    left: -39px;
                    span {
                        display: none;            
                    }
                    img {
                        height: 26px;
                    }
                }
            }
        }
    }

    .leaderboard-actions {
        margin: 0;
        padding: 20px 0;
        position: fixed;
        width: 100vw;
        z-index: 2;
        bottom: 0;
        left: 0;
        background: rgba(30, 30, 30, 0.95);
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(4px);
    
        .new-game-btn {
            width: 380px;
            font-size: 24px;
            line-height: 28px;
        }
        .replay-btn {
            width: 268px;
            font-size: 24px;
            line-height: 28px;
            img {
                width: 29px;
                height: 29px;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .leaderboard-container {
        max-width: calc(100% + 39px);
        width: calc(100% + 39px);
        margin-right: 39px;
        padding: 12px 0 0;
        .scroll-container {
            padding-left: 39px;
        }
        .playerItem-container {
            margin-bottom: 12px;
            margin-left: 0;
            margin-right: 0;

            .item {
                height: 48px;
                border-radius: 8px;
                font-size: 16px;
                line-height: 19px;
                border-radius: 12px;
            }
            .place {
                width: 60px;
                img {
                    width: 39px;
                    height: 39px;
                }
            }
            .player-name {
                padding: 8px;
                .avatar {
                    width: 32px;
                    height: 32px;
                } 
            }
            .score {
                padding: 7px 32px 7px 22px;
                img {
                    width: 33px;
                    height: 35px;
                    margin-right: 14px;
                }
                .score-item {
                    min-width: 34px
                }
            }
            .you {
                .arrow-container {
                    position: absolute;
                    top: calc(50% - 13px);
                    left: -39px;
                    span {
                        display: none;            
                    }
                    img {
                        height: 26px;
                    }
                }
            }
        }
    }
    .leaderboard-actions {
        padding: 16px 20px 35px;
        flex-wrap: wrap;
        .replay-btn-container {
            order: 1;
            width: calc(100% - 42px);  
        }
        .new-game-container {
            order: 3;
            margin-top: 16px;
            width: 100%;
        }
        .new-game-btn {
            width: 100%;
            padding: 10px 0 !important;
            font-size: 18px !important;
            line-height: 27px !important;
            img {
                width: 21px;
                height: 21px;
            }
        }
        .replay-btn {
            width: 100%;
            padding: 10px 0 !important;
            font-size: 18px !important;
            line-height: 27px !important;
            margin: 0 0 16px;
            img {
                width: 21px;
                height: 21px;
            }
        }
        .leaderboard-timer {
            order: 2;
            font-size: 18px;
            line-height: 21px;
            margin-left: 12px;
        }
    }
}

@media only screen and (max-width: 574px) {
    .leaderboard-container {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        padding: 12px 0 0;
        .scroll-container {
            padding-left: 0px;
        }
        .playerItem-container {
            .item {
                height: 40px;
                border-radius: 6px;
                font-size: 14px;
                line-height: 16px;
            }
            .place {
                width: 40px;
                img {
                    width: 32px;
                    height: 32px;
                }
            }
            .player-name {
                padding: 4px; 
            }
            .score {
                padding: 8px 16px 8px 8px;
                img {
                    width: 22px;
                    height: 23px;
                    margin-right: 14px;
                }
            }
            .you {
                .arrow-container {
                    display: none;
                }
            }
        }
    }
    .leaderboard-actions {
        .leaderboard-timer {
            position: relative;
            top: -6px;
            left: 0;
        }
    }
}
